import { DialogRef } from '@angular/cdk/dialog';
import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  contentChildren,
  inject,
  input,
} from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ModalActionDirective } from '../../../directives/modal-action/modal-action.directive';
import { ButtonComponent } from '../../button/button.component';

/*
 * @example
 * <app-modal-actions>
 *   <app-button
 *     [backgroundColor]="'transparent'"
 *     [isDisabled]="false"
 *     (click)="onSave()"
 *     appModalAction
 *   >
 *     {{ t('save') }}
 *  </app-button>
 * </app-modal-actions>
 *
 */
@Component({
  selector: 'app-modal-actions',
  templateUrl: './modal-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoModule, ButtonComponent, TitleCasePipe],
})
export class ModalActionsComponent {
  readonly changeDetectorReference = inject(ChangeDetectorRef);
  readonly #dialogReference = inject(DialogRef);

  /**
   * @deprecated  This is a workaround until the old modal is removed
   */
  readonly doesOldModalHaveActionButtons = input<boolean>(false);

  canModalBeClosed_ = true;
  shouldShowCancelButton_ = true;

  readonly contentChildren = contentChildren(ModalActionDirective, {
    descendants: true,
  });

  readonly areActionButtonsPresent = computed(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () =>
      this.contentChildren().length > 0 || this.doesOldModalHaveActionButtons(),
  );

  onCloseButtonClick(): void {
    if (!this.canModalBeClosed_) {
      return;
    }

    this.#dialogReference.close();
  }
}
