<!-- TODO: add animation? -->
<div
  class="tw-flex tw-h-full tw-w-full tw-flex-col tw-rounded-lg tw-bg-white tw-shadow-3 print:tw-hidden"
  *transloco="let t; read: 'shared'"
  [ngClass]="{
    'tw-p-8': version === '1.0.0',
    'tw-gap-y-4 tw-p-3 md:tw-p-6 xl:tw-gap-y-6': version === '2.0.0',
  }"
  data-testid="modal"
>
  <div
    class="tw-flex"
    [ngClass]="{
      'tw-mb-2.5': version === '1.0.0',
    }"
  >
    <ng-content select="app-modal-title" />
    <ng-container *ngrxLet="!canBeClosed_ as isCloseIconButtonDisabled">
      @if (shouldShowCloseIconButton) {
        <app-icon-button-new
          class="tw-ml-auto"
          [appCustomTitle]="isCloseIconButtonDisabled ? '' : t('closeModal')"
          [isDisabled]="isCloseIconButtonDisabled"
          [label]="t('close')"
          (click)="onCloseIconButtonClick()"
        >
          <app-icon [icon]="'farXmark'" />
        </app-icon-button-new>
      }
    </ng-container>
  </div>
  <!-- TODO: add `tw-overflow-y-scroll tw-pr-2.5`? -->
  <div class="tw-min-h-0 tw-grow">
    <ng-content select="app-modal-content" />
  </div>
  @if (modalNewActionsComponent) {
    <div
      [ngClass]="{
        'tw-mt-4': version === '1.0.0',
      }"
    >
      <ng-content select="app-modal-actions" />
    </div>
  }
</div>
