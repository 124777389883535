import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingComponent } from '../../heading/heading.component';

@Component({
  selector: 'app-modal-title',
  templateUrl: './modal-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeadingComponent],
})
export class ModalTitleComponent {}
