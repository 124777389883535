import { DialogRef } from '@angular/cdk/dialog';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostListener,
  inject,
  Input,
  type AfterViewInit,
} from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { LetDirective } from '@ngrx/component';
import { CustomTitleDirective } from '../../directives/custom-title/custom-title.directive';
import { IconButtonNewComponent } from '../icon-button-new/icon-button-new.component';
import { IconComponent } from '../icon/icon.component';
import { ModalActionsComponent } from './modal-actions/modal-actions.component';

export type ModalVersion = '1.0.0' | '2.0.0';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CustomTitleDirective,
    IconButtonNewComponent,
    IconComponent,
    LetDirective,
    NgClass,
    TranslocoModule,
  ],
})
export class ModalComponent implements AfterViewInit {
  readonly #changeDetectorReference = inject(ChangeDetectorRef);
  readonly #dialogReference = inject(DialogRef);

  // TODO: remove when app-modal component is removed
  /**
   * @deprecated
   */
  @Input()
  set canBeClosed(value: boolean) {
    this.canBeClosed_ = value;

    if (this.modalNewActionsComponent) {
      this.modalNewActionsComponent.canModalBeClosed_ = value;
      this.modalNewActionsComponent.changeDetectorReference.detectChanges();
    }
  }

  @Input()
  shouldShowCancelButton = true;

  @Input()
  version: ModalVersion = '1.0.0';

  @ContentChild(ModalActionsComponent)
  readonly modalNewActionsComponent: ModalActionsComponent | undefined;

  shouldShowCloseIconButton = true;
  canBeClosed_ = true;

  @HostListener('keydown.esc')
  onEscapeKeydown(): void {
    this.close();
  }

  ngAfterViewInit(): void {
    if (this.modalNewActionsComponent) {
      this.shouldShowCloseIconButton = false;
      this.#changeDetectorReference.detectChanges();

      this.modalNewActionsComponent.canModalBeClosed_ = this.canBeClosed_;
      this.modalNewActionsComponent.shouldShowCancelButton_ =
        this.shouldShowCancelButton;
      this.modalNewActionsComponent.changeDetectorReference.detectChanges();
    }
  }

  onCloseIconButtonClick(): void {
    this.close();
  }

  private close(): void {
    if (!this.canBeClosed_) {
      return;
    }

    this.#dialogReference.close();
  }
}
